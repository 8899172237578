import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import compact from 'lodash/compact';
import get from 'lodash/get';
import filter from 'lodash/filter';
import Button from '../components/Button/Button';
import { GlobalPageContext } from '../types';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../components/LayoutWrapper';
import { StrapiNotFoundPageQuery } from '../../graphql.schema';
import { v4tov3base } from '../lib/mapV4toV3';

import * as baseStyles from '../styles/base.module.scss';
import * as styles from './page.module.scss';
import { ContentBlock } from '../components/ContentBlock/ContentBlock';
import classNames from 'classnames';

type PageContextType = GlobalPageContext;

const PageNotFound: React.FC<PageProps<StrapiNotFoundPageQuery, PageContextType>> = ({ data = [], pageContext }) => {
  const { websiteLocale: websiteLocaleV3, website: websiteV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3 });
  const cards = filter(get(data, 'strapi.pages[0].components', []), ['__typename', 'Strapi_ComponentPageImageAndText'])

  if (!websiteLocale) return null;

  return (
    <LayoutWrapper {...pageContext}>
      <main role="main" className={classNames("container", styles.container, styles.wse404container)}>
        <div>
          <h1 className={baseStyles.title}>
            <FormattedMessage id="notFoundTextHeading" />
          </h1>
        </div>
        <div className={classNames(baseStyles.header, styles.wse404Header)}>
          <FormattedMessage tagName="p" id="notFoundTextDescription" />
          <p className='mt-6'>
            <Button href={'/'}>
              <FormattedMessage id="notFoundReturnButtonText" />
            </Button>
          </p>
        </div>
        {cards.length ? <div className={baseStyles.content}>
          <div className={styles.container}>
            <div className={styles.wse404Main}>
              <div className={styles.gridContainer}>
                {cards.map((card) => <>
                  <div className={`${styles.wse404Content} mr-5`}>
                    <img height={card?.mandatory_image?.height} width={card?.mandatory_image?.width} src={card?.mandatory_image?.url} alt="" />
                    <ContentBlock
                      isFluid
                      contentClass={styles.wse404RichContent}
                      icon='Arrow'
                      title={card.title}
                      content={card.intro ?? ''}
                      links={compact(card.links)}
                      button={card.button ?? undefined}
                    />
                  </div>
                </>)}
              </div>
            </div>
          </div>
        </div> : <></>}
      </main>
    </LayoutWrapper >
  );
};


export const query = graphql`
query StrapiNotFoundPage($websiteLocaleId: ID!, $locale: Strapi_I18NLocaleCode) {
  strapi {
    pages(filters: {website_locale: {documentId: {eq: $websiteLocaleId}}, index_page: {eq: "NotFound"}},
    locale: $locale) {
      data {
        documentId
        attributes {
          title
          slug
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          components {
            ...PageComponents
          }
          pageSettings: page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(PageNotFound));
